export enum ModelStatus {
	Waiting,
	Accepted,
	ProfileNotSet,
	WaitingEmailVerification,
	Pending,

	Deleted = 30,
	Blocked,
	BlockedDeleted,
	Restricted,
	Declined,

	Active=35,
	Deactivate=36
}
