import { CommunityVisiblity } from "../../../constants/community-visiblity";
import { ModelStatus } from "../../../constants/model-status";
import { ValidationUtility } from "../../../validation-utility";
import { IValidatorType } from "../../../validator-interface";
import { ICommunity, ICommunityFeature, IPrivilege, ITag } from "../platform-admin";

//Req [GET] /platform/admin/community
//q-com name com admin email 
//classification
//level
//visibility
export interface ICommunityRequest {
	start: number,
	count: number,
	communityId?: any,
	query?: string,
	status?: number, //0,1,30,31,32
	layer?: number,
	visibility?: CommunityVisiblity,
	classification?: string //comma separated
}
let validStatus = [ModelStatus.Accepted, ModelStatus.Waiting, ModelStatus.Deleted, ModelStatus.Blocked, ModelStatus.BlockedDeleted]
//Val [GET] /platform/admin/community
export const VCommunity: IValidatorType<ICommunityRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	query: { function: ValidationUtility.validateStringFields, params: [0, -1], required: false },
	status: { function: ValidationUtility.validateModelStatus, params: [validStatus], required: false },
	visibility: { function: ValidationUtility.validateNumber, params: [], required: false },
	layer: { function: ValidationUtility.validateNumber, params: [0, 1], required: false },
	classification: { function: ValidationUtility.validateStringFields, params: [0, -1], required: false }
}

//Res [GET] /platform/admin/community
export interface ICommunityResponse {
	start: number,
	count: number,
	nextStart: boolean,
	totalCount: number,
	content: ICommunity[]

}

//Req [PUT] /platform/admin/community
export interface ICommunityUpdateRequest {
	communityId: any,
	status: ModelStatus,
	name?: string,
	classifications?: any[],
	features?: any[],
	size?: any,
	affilatedTags?: any[],
	about?: string,
	yearFounded?: number,
	address?: string,
	visibility?: CommunityVisiblity,
	industries?: any[],
	specialities?: any[],
	websiteLink?: string,
	imageId?: any,
	featureImageId?: any,
	userProfileWriteAccess?: boolean,
	adminEmail?: string,
	adminName?: string
	hideCreate?: boolean,
	hideExplore?: boolean,
}

//Val [PUT] /platform/admin/community
export const VCommunityUpdate: IValidatorType<ICommunityUpdateRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [0, -1], required: true },
	status: { function: ValidationUtility.validateNumber, params: [], required: true },
	name: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	classifications: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	features: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	size: { function: ValidationUtility.validateNumber, params: [1, -1], required: false },
	affilatedTags: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	about: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	yearFounded: { function: ValidationUtility.validateNumber, params: [], required: false },
	address: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	visibility: { function: ValidationUtility.validateNumber, params: [], required: false },
	industries: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	specialities: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	websiteLink: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	imageId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	featureImageId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	userProfileWriteAccess: { function: ValidationUtility.validateBoolean, params: [], required: false },
	adminEmail: { function: ValidationUtility.validateEmail, params: [3, -1], required: false },
	adminName: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	hideCreate: { function: ValidationUtility.validateBoolean, params: [], required: false },
	hideExplore: { function: ValidationUtility.validateBoolean, params: [], required: false },
}

//Res [PUT] /platform/admin/community
export interface ICommunityUpdateResponse { }

//--------------------------------------------------------------------------------------------------	

//Req [POST] /platform/admin/community
export interface ICommunityCreateRequest {
	name: string,
	classifications: any[],
	features: any[],
	size: any,
	affilatedTags: any[],
	about: string,
	yearFounded?: number,
	address?: string,
	visibility: CommunityVisiblity,
	industries?: any[],
	specialities?: any[],
	websiteLink?: string,
	adminName?: string,
	adminEmail: string,
	imageId?: any,
	featureImageId?: any,
	emailFlag: boolean,
	userProfileWriteAccess: any
}

//Val [POST] /platform/admin/community
export const VCommunityCreate: IValidatorType<ICommunityCreateRequest> = {
	name: { function: ValidationUtility.validateStringFields, params: [3, -1], required: true },
	classifications: { function: ValidationUtility.validateObjectArrayId, params: [], required: true },
	features: { function: ValidationUtility.validateObjectArrayId, params: [], required: true },
	size: { function: ValidationUtility.validateNumber, params: [1, -1], required: true },
	affilatedTags: { function: ValidationUtility.validateObjectArrayId, params: [], required: true },
	about: { function: ValidationUtility.validateStringFields, params: [3, -1], required: true },
	address: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	yearFounded: { function: ValidationUtility.validateNumber, params: [], required: false },
	visibility: { function: ValidationUtility.validateNumber, params: [], required: true },
	industries: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	specialities: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	websiteLink: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	adminName: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	adminEmail: { function: ValidationUtility.validateEmail, params: [3, -1], required: true },
	imageId: { function: ValidationUtility.validateObjectId, params: [1, 1], required: false },
	featureImageId: { function: ValidationUtility.validateObjectId, params: [1, -1], required: false },
	emailFlag: { function: ValidationUtility.validateBoolean, params: [1, -1], required: false },
	userProfileWriteAccess: { function: ValidationUtility.validateBoolean, params: [], required: true },
}

//Res [POST] /platform/admin/community
export interface ICommunityCreateResponse {
	communityId: any
}

//--------------------------------------------------------------------------------------------------	

//Req [GET] /platform/admin/community/feature
export interface ICommunityFeatureRequest {
	communityId: any,
}

//Val [GET] /platform/admin/community/feature
export const VCommunityFeature: IValidatorType<ICommunityFeatureRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: true },
}

//Res [GET] /platform/admin/community/feature
export interface ICommunityFeatureReseponse {
	communityId: any,
	features: ICommunityFeature[]
}

//--------------------------------------------------------------------------------------------------

//Req [PUT] /platform/admin/community/feature
export interface ICommunityFeatureUpdateRequest {
	communityId: any,
	feature: ICommunityFeature
}

//Val [PUT] /platform/admin/community/feature
export const VCommunityFeatureUpdate: IValidatorType<ICommunityFeatureUpdateRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [0, -1], required: true },
	feature: { function: ValidationUtility.validateAny, params: [], required: true }
}

//Res [PUT] /platform/admin/community/feature
export interface ICommunityFeatureUpdateResponse { }

//--------------------------------------------------------------------------------------------------

//Req [GET] /platform/admin/community/request
export interface IRequestCommunityRequest {
	start: number,
	count: number,
	// communityName?: string,
	// communityId: any,
}

//Val [GET] /platform/admin/community/request
export const VCommunityRequest: IValidatorType<IRequestCommunityRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	// communityName: { function: ValidationUtility.validateStringFields, params: [0, 1], required: false },
	// communityId: { function: ValidationUtility.validateObjectId, params: [0, 1], required: false },
}

//Res [GET] /platform/admin/community/request
export interface IRequestCommunityResponse {
	start: number,
	count: number,
	nextStart: boolean,
	content: ICommunity[]
}

//--------------------------------------------------------------------------------------------------

//Req [POST] /platform/admin/community/request
export interface ICommunityRequestUpdateRequest {
	communityId: any,
	status: ModelStatus
}

//Val [POST] /platform/admin/community/request
export const VCommunityRequestUpdate: IValidatorType<ICommunityRequestUpdateRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: true },
	status: { function: ValidationUtility.validateNumber, params: [], required: true }
}

//Res [POST] /platform/admin/community/request
export interface ICommunityRequestUpdateResponse { }

//--------------------------------------------------------------------------------------------------

//Req [GET] /platform/admin/community/tags
export interface ICommunityTagRequest {
	start: number,
	count: number,
	tagName?: any,
	status?: ModelStatus
}

//Val [GET] /platform/admin/community/tag
export const VCommunityTag: IValidatorType<ICommunityTagRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	tagName: { function: ValidationUtility.validateAny, params: [], required: false },
	status: { function: ValidationUtility.validateNumber, params: [0, 1], required: false }
}

//Res [GET] /platform/admin/community/tag
export interface ICommunityTagResponse {
	start: number,
	count: number,
	nextStart: boolean,
	content: ITag[]
}

//--------------------------------------------------------------------------------------------------

//Req [PUT] /platform/admin/community/tag
export interface ICommunityTagUpdateRequest {
	tagId: any,
	tagName?: string,
	status: ModelStatus
}

//Val [PUT] /platform/admin/community/tag
export const VCommunityTagUpdate: IValidatorType<ICommunityTagUpdateRequest> = {
	tagId: { function: ValidationUtility.validateObjectId, params: [0, -1], required: true },
	tagName: { function: ValidationUtility.validateStringFields, params: [3, -1], required: false },
	status: { function: ValidationUtility.validateNumber, params: [0, 1], required: true }
}

//Res [PUT] /platform/admin/community/tag
export type ICommunityTagUpdateResponse = ITag

//Req [PUT] /platform/admin/community/email
export interface ICommunityUpdateEmailSettingRequest {
	communityId: any,
	platformAdminEmailFlag: boolean
}

//Val [PUT] /platform/admin/community/email
export const VCommunityEmailSettingUpdate: IValidatorType<ICommunityUpdateEmailSettingRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: true },
	platformAdminEmailFlag: { function: ValidationUtility.validateBoolean, params: [], required: true }
}

//Res [PUT] /platform/admin/community/email
export interface ICommunityUpdateEmailSettingResponse { }

//Req [PUT] /platform/admin/community/explore
export interface ICommunityUpdateExploreFlagRequest {
	communityId: any,
	isVisibleOnExplore: boolean
}

//Val [PUT] /platform/admin/community/explore
export const VCommunityExplorFlagUpdate: IValidatorType<ICommunityUpdateExploreFlagRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: true },
	isVisibleOnExplore: { function: ValidationUtility.validateBoolean, params: [], required: true }
}

//Res [PUT] /platform/admin/community/explore
export interface ICommunityUpdateExploreFlagResponse { }

