export const ResponseCodes = {
	general: 511,
	inputError: 512,

	userError: 515,
	loginError: 520,
	searchError: 525,
	insertError: 526,
	saveError: 527,
	deleteError: 528,
	pythonError: 530,
	adminError: 531,
	emailError: 532,
	requestError: 533,
	priviledgeError: 534,
	firebaseError: 536,
	shareError: 538,
	userLimitError: 539,

	hackError: 550,
	accountTokenError: 551,
	sessionTokenError: 552,
	communityTokenError: 553,

	googlePlacesError: 580,
	messageError: 585,

	stripeError: 590,
	mattermostError: 591,

	rewardfulrError: 592,

	serverError: 595,
	ZoomError: 597,

	internalServerError: 500,
}
