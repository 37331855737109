import { ICommunityTokenResponse } from "../community-routes/community-routes";
import { ICommunitySingleResponse } from "../profile-routes/profile-routes";
import { ResponseMessages } from "../response-messages";
import { ValidationUtility } from "../validation-utility";
import { IValidatorInterface } from "../validator-interface";

//Req [POST] /login
export interface IUserLoginRequest {
	email?: string,
	password?: string,
	communityId?: string //Send this when we are trying to login from the Community Login Directly
	accessToken?: string,
	refreshToken?: string,
	type?: string,
	referId?: string,
}
export interface IUserlinkedinRequest {
	code: string
	communityId?: string
}
export interface IUserlinkedinRequest {
	code: string
	communityId?: string
}

//Val [POST] /login
export const VUserLogin: IValidatorInterface = {
	email: { function: ValidationUtility.validateEmail, params: [], customMessage: ResponseMessages.errorEmail },
	password: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false }
}

export const SocialUserLogin: IValidatorInterface = {
	email: { function: ValidationUtility.validateEmail, params: [], customMessage: ResponseMessages.errorEmail },
	accessToken: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	refreshToken: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	referId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false },
	type: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
}

export const linkedinlogin: IValidatorInterface = {
	code: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false }
}
export type IlinkedincallbakeGetResponse = {
	profilePhoto: string,
	name: string,
	email: string,
	userid: string
}

//Res [POST] /login
export interface IUserLoginResponse {
	sessionToken: string,
	community: {
		onboardToken?: any
	} & Partial<ICommunityTokenResponse>
	user: {
		userId: string,
		name: string,
		email: string,
		image: string, //DONE: @rahul I am seeing that its return just the ID, we need url here
		title?: string, //DONE: @rahul Please add designation in this place
		isPlatformAdmin?: boolean
	},
	FirebaseToken?: {
		accessToken: string,
		refreshToken: string
	},
	communityDetails: {
		communityId: string
		name: string,
		image?: string,
		layer: number,
		isAdmin: boolean,
		userProfileWriteAccess?: boolean,
		lastOpenedTime: number,
		memberLandingPage: number,
		nonmemberLandingPage: number
	},
	redirectDetails?: {
		communityId: string,
		shouldRedirectExplore: boolean,
	},
	mattermost?: {
		loginId: string,
		password?: string,
		cookies?: { [k: string]: string }
	}
	status: number
}

//Req [GET] /login
export interface IUserLoginGetRequest {
	communityId: string //Send this when we are trying to login from the Community Login Directly
}

//Val [GET] /login
export const VUserLoginGet: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateObjectId, params: [] }
}

//Res [GET] /login
export type ILoginGetResponse = ICommunitySingleResponse & {
	inviterName: string,
	readableId?: string
}