import { ModelStatus } from "../../constants/model-status";
import { ICommunitySingleResponse } from "../../profile-routes/profile-routes";
import { IProfileEducationGetResponse } from "../../profile-routes/sub-routes/profile-education-routes";
import { IProfileExperienceGetResponse } from "../../profile-routes/sub-routes/profile-experience-route";
import { IProfileExpertiseGetResponse } from "../../profile-routes/sub-routes/profile-expertise-routes";
import { IProfileIndustryGetResponse } from "../../profile-routes/sub-routes/profile-industry-routes";
import { IProfileInterestGetResponse } from "../../profile-routes/sub-routes/profile-interest-route";
import { IProfileMembershipGetResponse } from "../../profile-routes/sub-routes/profile-membership-routes";
import { ValidationUtility } from "../../validation-utility";
import { IValidatorType } from "../../validator-interface";

//Global interfaces for Platform Admin

export interface IUser {
	userId: string;
	name: string;
	email?: string;
	about?: string;
	image?: any;
	mobile?: string;
	location?: string;
	resume?: string;
	designation?: string;
	company?: string;
	onboardCompany?: string,
	onboardTime?: number,
	industry?: IProfileIndustryGetResponse;
	communities?: ICommunitySingleResponse[];
	interests?: IProfileInterestGetResponse;
	expertise?: IProfileExpertiseGetResponse;
	education?: IProfileEducationGetResponse;
	experiences?: IProfileExperienceGetResponse;
	memberships?: IProfileMembershipGetResponse;
	status?: ModelStatus;
}

export interface ICommunity {
	communityId: any,
	name: string,
	about: string,
	layer: number,
	status?: number,
	imageUrl?: string,
	yearFounded?: any,
	featureImageUrl?: string,
	adminUser?: IUser
	createTimestamp?: number,
	totalUser?: number,
	industries?: IIndustry[],
	classifications?: IClassification[],
	specialities?: ISpecialities[],
	size?: number,
	location?: string,
	visibility?: number,
	websiteLink: string,
	affiliatedTags: ITag[],
	features: IFeature[],
	emailFlag: boolean,
	userProfileWriteAccess: any,
	isVisibleOnExplore: boolean,
	hideCreate?: boolean,
	hideExplore?: boolean
	paidCommunity?: boolean
}

export interface IFeature {
	featureId: any,
	featureName?: string
	description?: string
	image?: string
	image_white?: string,
	isDefault?: boolean,
	isAdvanceFeature?: boolean
	isCore?: boolean,
	isCommon?: boolean,
	status?: any
}

export type ICommunityFeature = IFeature & {
	privileges: IPrivilege[]
}

export interface IPrivilege {
	privilegeId: any,
	privilageName?: string
	status: any //boolean for community endpoint and ModelStatus for privilege endpoint
}

export interface IReport {
	reportId: any,
	post: any,
	reason: string,
	timeStamp?: number,
	status?: ModelStatus
}

export interface ITag {
	tagId: any,
	name: string,
	description: string,
	defaultPrivileges?: IPrivilege[],
	timeStamp?: number,
	status?: ModelStatus,
}

export interface IIndustry {
	industryId: any,
	name: string,
	status?: ModelStatus,
	userCount?: number
}

export interface IClassification {
	classificationId: any,
	name: string,
}

export interface ISpecialities {
	expertiseId: any,
	name: string
}
//--------------------------------------------------------------------------------------------------

//Req [GET] /platform/admin
export interface IGetPlatformAdminUsersRequest {
	start: number,
	count: number,
	filter?: {
		userId: any,
		name: string,
		stutus: ModelStatus,
		// more filters
	}


}

//Val [GET] /platform/admin
export const VPlatformAdminUsers: IValidatorType<IGetPlatformAdminUsersRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	count: { function: ValidationUtility.validateNumber, params: [0, -1], required: true },
	filter: { function: ValidationUtility.validateAny, params: [0, 1], required: false }
}

//Res [GET] /platform/admin
export interface IGetPlatformAdminUsersResponse {
	start: number,
	count: number,
	nextStart: boolean
	content: {
		user: IUser[]
	}
}

//--------------------------------------------------------------------------------------------------

//Req [POST] /platform/admin
export interface IPostPlatformAdminUsersRequest {
	userId: any
}

//Val [POST] /platform/admin
export const VPlatformAdminUsersCreate: IValidatorType<IPostPlatformAdminUsersRequest> = {
	userId: { function: ValidationUtility.validateObjectId, params: [24, 24], required: true }
}

//Res [POST] /platform/admin
export interface IPostPlatformAdminUsersResposne { }

//--------------------------------------------------------------------------------------------------

//Req [DELETE] /platform/admin
export interface IDeletePlatformAdminUsersRequest {
	userId: any
}

//Val [DELETE] /platform/admin
export const VPlatformAdminUsersDelete: IValidatorType<IDeletePlatformAdminUsersRequest> = {
	userId: { function: ValidationUtility.validateObjectId, params: [24, 24], required: true }
}

//Res [DELETE] /platform/admin
export interface IDeletePlatformAdminUsersResponse { }

//Res [GET] /platform/generateWeeklyReport

export interface IGenerateWeeklyReport {
	communityId: string,
}

export interface IDeleteCommunity {
	communityId: string,
}

export const VGenerateWeeklyReport: IValidatorType<IGenerateWeeklyReport> = {
	communityId: { function: ValidationUtility.validateStringFields, params: [24, 24], required: true },
}

export const VDeleteCommunity: IValidatorType<IDeleteCommunity> = {
	communityId: { function: ValidationUtility.validateStringFields, params: [24, 24], required: true },
}