// @ts-ignore
import { SubscriptionPlanDuration, SubscriptionPlanStatus, SubscriptionPlanVisibility } from "../../models/subscription-plans-model";
import { ValidationUtility } from "../validation-utility";
import {IValidatorInterface, IValidatorType} from "../validator-interface";

// POST /plan
export const VPlanCreate: IValidatorInterface = {
	name: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan name is required" },
	amount: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Plan amount is required" },
	status: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Status is required" },
	visibility: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Visibility is required" },
	description: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan description is required" },
	paymentType: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Payment type is required" },
	paymentDuration: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Payment duration is required" },
	backgroundColor: { function: ValidationUtility.validateStringFields, params: [1, 7], required: false },
	foregroundColor: { function: ValidationUtility.validateStringFields, params: [1, 7], required: false },
	assignMember: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	image: { function: ValidationUtility.validateObjectId, params: [], required: false },
}

// PUT /plan
export const VPlanUpdate: IValidatorInterface = {
	planId: { function: ValidationUtility.validateObjectId, params: [1, -1], required: true, customMessage: "Plan ID not valid!" },
	name: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan name is required" },
	// amount: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Plan amount is required" },
	status: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Status is required" },
	visibility: { function: ValidationUtility.validateNumber, params: [1, -1], required: true, customMessage: "Visibility is required" },
	description: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan description is required" },
	// paymentType: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Payment type is required" },
	// paymentDuration: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Payment duration is required" },
	backgroundColor: { function: ValidationUtility.validateStringFields, params: [1, 7], required: false },
	foregroundColor: { function: ValidationUtility.validateStringFields, params: [1, 7], required: false },
	assignMember: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	image: { function: ValidationUtility.validateObjectId, params: [], required: false },
}
export interface IUpdatePlanResponse {
}

export interface ICreatePlanRequest {
	image?: any,
	name: string,
	amount: number,
	status: SubscriptionPlanStatus,
	visibility: SubscriptionPlanVisibility,
	description: string,
	paymentType: string,
	paymentDuration: SubscriptionPlanDuration,
	assignMember?: any[],
	backgroundColor?: string,
	foregroundColor?: string,
	timeStamp?: number
}

export interface IPlansResponse {
	image: any,
	name: string,
	amount: number,
	status: SubscriptionPlanStatus,
	visibility: SubscriptionPlanVisibility,
	description: string,
	paymentType: string,
	paymentDuration: SubscriptionPlanDuration,
	assignMember: any[],
	backgroundColor?: string,
	foregroundColor?: string,
	timeStamp: number,
	imageId: string,
	communityId: string,
	priceId: string,
	productId: string,
	planId: string
}

export interface ICreatePlanResponse {
}

// DELETE Plan
export interface IDeletePlanRequest {
	planId: string;
}
export const VPlanDelete: IValidatorInterface = {
	planId: { function: ValidationUtility.validateObjectId, params: [], required: true, customMessage: "Plan ID not valid!" },
}
export interface IDeletePlanResponse {
}

// POST plan/assignedmembers
export interface IAssignedMembersRequest {
	planId: string;
}
export const VAssignedMembers: IValidatorInterface = {
	planId: { function: ValidationUtility.validateObjectId, params: [], required: true, customMessage: "Plan ID not valid!" },
}
export interface IAssignedMembersResponse {
	userId: string,
	name: string,
	email: string,
	image: string,
}

// POST plan/get-subscription-url
export interface IGetSubscriptionUrlRequest {
	planId: string;
	success_url: string;
	cancel_url: string;
}
export const VGetSubscriptionUrls: IValidatorInterface = {
	planId: { function: ValidationUtility.validateObjectId, params: [], required: true, customMessage: "Plan ID not valid!" },
	success_url: { function: ValidationUtility.validateUrl, params: [], required: true, customMessage: "Success url not valid!" },
	cancel_url: { function: ValidationUtility.validateUrl, params: [], required: true, customMessage: "Cancel url not valid!" },

}

export const VUpdateSubscription: IValidatorInterface = {
	priceId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Price Id not valid!" },
	planId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Plan Id not valid!" },
	paymentMethod: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false, customMessage: "paymentMethod not valid!" },
	address: {function: ValidationUtility.validateStripeAddressObject,params: [], required: false, customMessage: "missing fields in address"}
}

export const VExtendSubscription: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	extendTill: { function: ValidationUtility.validateNumber, params: [], required: true, customMessage: "Please select the date to extend trial till." }
}

export const VCancelSubscription: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	reasonType: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false },
	reason: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false }
}

export const VToggleCommunityStatus: IValidatorInterface = {
	communityId: { function: ValidationUtility.validateStringFields, params: [1, -1], required: true, customMessage: "Community ID is required!" },
	isPaid: { function: ValidationUtility.validateBoolean, params: [], required: true, customMessage: "please also include the status to change" }
}
export interface IGetSubscriptionUrlResponse {
	url: string,
}

export interface IPutUpdateSubscriptionResponse {
	updated: boolean,
	message: string
}
export interface IPutUpdateSubscription {
	priceId: string;
	planId: string;
	paymentMethod?: string;
	address?: IStripeSubscriptionAddress
}
export interface IExtendSubscription {
	communityId: string;
	extendTill: number;
}
export interface ICancelSubscription {
	communityId: string;
	reasonType?: string;
	reason?: string;
}
export interface IToggleCommunityStatus {
	communityId: string;
	isPaid: boolean
}

export interface IStripeSubscriptionAddress {
	country: string;
	state: string;
	city: string;
	line1: string;
	line2?: string;
	postal_code: string;
}
